import React from "react";
import Song from "../../Song";

const ClashTitle = () => (
  <div>
    <Song />
    <h1>PICK YOUR TOPPING!</h1>
  </div>
);

export default ClashTitle;
